/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: Benji Labs (https://sketchfab.com/benjilabs)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/home-office-blender-asset-pack-9abf2f407b2945dd9fbdf918ce6e10ff
Title: Home Office | Blender Asset Pack
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function HomeOffice(props) {
  const { nodes, materials } = useGLTF('./models/home_office_visual_2.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <group
          position={[-101.504, 58.947, -98.756]}
          rotation={[-Math.PI / 2, 0, -0.518]}
          scale={63.908}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Large_Plant_Plant_Leaf_Shader_0.geometry}
            material={materials.Plant_Leaf_Shader}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Large_Plant_Pot_Shader_0.geometry}
            material={materials.Pot_Shader}
          />
        </group>
        <group
          position={[-55.636, 92.722, -99.365]}
          rotation={[-Math.PI / 2, 0, 0.301]}
          scale={[1.298, 1.298, 0.255]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Studio_Speaker002_Speaker_Black_0.geometry}
            material={materials.Speaker_Black}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Studio_Speaker002_Speaker_White_0.geometry}
            material={materials.Speaker_White}
          />
        </group>
        <group
          position={[67.695, 137.12, -122.662]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#3_Poster_Border_Black_0'].geometry}
            material={materials.Poster_Border_Black}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#3_Poster_White_Border_0'].geometry}
            material={materials.Poster_White_Border}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#3_Art_03_0'].geometry}
            material={materials.Art_03}
          />
        </group>
        <group
          position={[-1.034, 137.12, -122.662]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#2_Poster_Border_Black_0'].geometry}
            material={materials.Poster_Border_Black}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#2_Poster_White_Border_0'].geometry}
            material={materials.Poster_White_Border}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#2_Art_01_0'].geometry}
            material={materials.Art_01}
          />
        </group>
        <group
          position={[-70.324, 137.12, -122.662]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#1_Poster_Border_Black_0'].geometry}
            material={materials.Poster_Border_Black}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#1_Poster_White_Border_0'].geometry}
            material={materials.Poster_White_Border}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Poster_#1_Art_02_0'].geometry}
            material={materials.Art_02}
          />
        </group>
        <group
          position={[65.315, -1.218, -82.024]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Desk_#2_Metal_Black_0'].geometry}
            material={materials.Metal_Black}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['Desk_#2_Desk_Oak_0'].geometry}
            material={materials.Desk_Oak}
          />
        </group>

        <group
          position={[8.16, -0.206, -18.496]}
          rotation={[-1.577, -0.003, -2.646]}
          scale={[41.749, 41.749, 35.161]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Computer_Chair_Black_Plastic_0.geometry}
            material={materials.Black_Plastic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Computer_Chair_Chair_Seat_0.geometry}
            material={materials.Chair_Seat}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Computer_Chair_Chrome_0.geometry}
            material={materials.Chrome}
          />
        </group>
        <group
          position={[-94.061, 0.953, -60.476]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[12.071, 12.071, 0.784]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Bin_Bin_Metal_0.geometry}
            material={materials.Bin_Metal}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Bin_Bin_Shader_0.geometry}
            material={materials.Bin_Shader}
          />
        </group>
        <group
          position={[53.878, 92.438, -99.426]}
          rotation={[-Math.PI / 2, 0, -0.232]}
          scale={[1.298, 1.298, 0.255]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Studio_Speaker_Speaker_Black_0.geometry}
            material={materials.Speaker_Black}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Studio_Speaker_Speaker_White_0.geometry}
            material={materials.Speaker_White}
          />
        </group>
        <group
          position={[-0.927, 78.535, -103.398]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={102.867}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.iMac_Silver_iMac_Grey_Shader_0.geometry}
            material={materials.iMac_Grey_Shader}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.iMac_Silver_iMac_Screen_0.geometry}
            material={materials.iMac_Screen}
          />
        </group>
        <group
          position={[-0.052, 70.162, -103.055]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[44.245, 9.054, 1.405]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Monitor_Riser_Desk_Oak_0.geometry}
            material={materials.Desk_Oak}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Monitor_Riser_Desk_Black_0.geometry}
            material={materials.Desk_Black}
          />
        </group>
        <group
          position={[-10.001, 71.597, -71.431]}
          rotation={[-1.529, 0.004, 0.092]}
          scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Keyboard_Keyboard_Keys_0.geometry}
            material={materials.Keyboard_Keys}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Keyboard_iMac_Grey_Aluminium_0.geometry}
            material={materials.iMac_Grey_Aluminium}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Keyboard_iMac_White_Glass_0.geometry}
            material={materials.iMac_White_Glass}
          />
        </group>
        <group
          position={[19.129, 71.158, -71.769]}
          rotation={[-Math.PI / 2, 0, -0.099]}
          scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Trackpad_iMac_White_Glass001_0.geometry}
            material={materials['iMac_White_Glass.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Trackpad_iMac_Grey_Aluminium_0.geometry}
            material={materials.iMac_Grey_Aluminium}
          />
        </group>

        <group position={[70, 0, -5]}>
          <group
            position={[126.237, 10.096, -100.134]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[28.397, 23.832, 1.187]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Book_Shelf_Desk_Oak_0.geometry}
              material={materials.Desk_Oak}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Book_Shelf_Metal_Black_0.geometry}
              material={materials.Metal_Black}
            />
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book051_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[100.983, 50.198, -93.45]}
            rotation={[Math.PI / 2, 0, 3.12]}
            scale={106.364}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book050_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[101.907, 52.959, -94.123]}
            rotation={[Math.PI / 2, 0, 3.02]}
            scale={94.953}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book049_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[101.488, 55.655, -93.45]}
            rotation={[Math.PI / 2, 0, 2.824]}
            scale={99.135}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book048_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[106.089, 125.218, -101.939]}
            rotation={[Math.PI / 2, 0, 3.025]}
            scale={112.018}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book047_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[126.493, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={80.23}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book046_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[124.226, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={82.586}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book045_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[121.707, 11.09, -88.579]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={105.57}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book044_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[118.864, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={112.018}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book043_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[116.057, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={100}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book042_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[113.25, 11.09, -88.579]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={104.404}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book041_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[110.551, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={94.767}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book040_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[152.184, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={112.018}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book039_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[149.377, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={100}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book038_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[146.57, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={104.404}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book037_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[143.871, 48.783, -91.526]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={94.767}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book036_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[141.388, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={86.01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book035_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[139.121, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={80.23}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book034_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[136.854, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={82.586}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book033_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[134.334, 48.783, -91.526]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={105.57}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book032_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[131.491, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={[79.408, 112.018, 112.018]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book031_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[128.684, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={100}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book030_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[125.877, 48.783, -90.635]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={104.404}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book029_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[123.178, 48.783, -91.526]}
            rotation={[Math.PI / 2, 1.525, -3.141]}
            scale={94.767}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book025_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[108.067, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={86.01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book024_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[105.8, 11.09, -88.579]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={80.23}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book023_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[103.533, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={82.586}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book022_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[101.014, 11.09, -87.906]}
            rotation={[-Math.PI / 2, 1.468, 0.001]}
            scale={105.57}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book002_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[107.013, 127.979, -102.612]}
            rotation={[Math.PI / 2, 0, 2.925]}
            scale={100}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Book001_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[106.594, 130.815, -101.939]}
            rotation={[Math.PI / 2, 0, 2.729]}
            scale={104.404}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Books_Book_Procedural_Shader_0.geometry}
            material={materials.Book_Procedural_Shader}
            position={[118.36, 86.142, -95.41]}
            rotation={[-Math.PI / 2, -0.009, 0.001]}
            scale={94.767}
          />

          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Small_Plant_Plant_Shader_0.geometry}
            material={materials.Plant_Shader}
            position={[143.802, 123.964, -104.841]}
            rotation={[-Math.PI / 2, 0, 1.463]}
            scale={[2.107, 0.971, 2.566]}
          />
        </group>

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Desk_Mat_Mat_Black_0.geometry}
          material={materials.Mat_Black}
          position={[-0.513, 70.291, -71.959]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={106.252}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./models/home_office_visual_2.glb')
