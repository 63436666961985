import React from 'react'
import { RoomModel } from './Room'
import { AzaReal } from './AzaReal'
import { HomeOffice } from './HomeOffice'
import { AzaSitting } from './AzaSitting'
import { PerspectiveCamera, useCurrentSheet } from '@theatre/r3f'
import { Environment, useScroll } from '@react-three/drei'
import { useFrame } from 'react-three-fiber'
import { val } from '@theatre/core'

export const Scene = () => {
  const sheet = useCurrentSheet()
  const scroll = useScroll()

  useFrame(() => {
    const sequenceLength = val(sheet.sequence.pointer.length)
    sheet.sequence.position = scroll.offset * sequenceLength
  })
  return (
    <>
      <Environment preset="forest" background />
      <PerspectiveCamera
        makeDefault
        theatreKey="Camera"
        position={[0, 0, 0]}
        fov={75}
        near={0.1}
        far={70}
      />

      <RoomModel />
      <AzaReal rotation={[0, Math.PI / 2, 0]} />
      {/* <AzaSitting /> */}
      <HomeOffice position={[-1.8, 0, -2.15]} />
    </>
  )
}
