/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: jimbogies (https://sketchfab.com/jimbogies)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/studio-room-c02efe2e7a10404895c2b49e3ca1d11a
Title: Studio Room
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from 'react-three-fiber'

export function RoomModel(props) {
  const { nodes, materials } = useGLTF('./models/studio_room.glb')

  // useFrame((scene, delta) => console.log(scene.camera.rotation))

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_2.geometry}
          material={materials.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3.geometry}
          material={materials.material}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./models/studio_room.glb')
