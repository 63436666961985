import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    powderWhite: '#FFFDF9',
    green1: '#36bfb1',
    green2: '#038c73',
    green3: '#02735e',
    green4: '#014034',
    onyx: '#36313D',
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
