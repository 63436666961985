import { Canvas } from '@react-three/fiber'
import React from 'react'
import styled from 'styled-components'
import { Scene } from './Scene'
import { ScrollControls } from '@react-three/drei'
import { getProject } from '@theatre/core'
import { SheetProvider } from '@theatre/r3f'
import PortfolioState from '../PortfolioState'
export const Canvasr3f = () => {
  const sheet = getProject('Portfolio', { state: PortfolioState }).sheet(
    'Sheet'
  )

  // const CustomCanvas = styled(Canvas)`
  //   > canvas {
  //     width: 100%;
  //     height: 100%;
  //   }
  // `

  return (
    <Canvas gl={{ preserveDrawingBuffer: true }} shadows>
      <ScrollControls pages={9}>
        <SheetProvider sheet={sheet}>
          <Scene />
        </SheetProvider>
      </ScrollControls>
      {/* <OrbitControls /> */}
    </Canvas>
  )
}
