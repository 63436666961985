import './App.css'
import { Canvasr3f } from './components/Canvasr3f.1'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Hero } from './components/Hero'
import { Slider } from './components/Slider'
import Theme from './components/theme'

function App() {
  return (
    <>
      <Theme>
        {/* <Hero /> */}
        {/* <Header /> */}
        {/* <Footer /> */}
        {/* <Slider /> */}
      </Theme>
      <Canvasr3f />
    </>
  )
}

export default App
